import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'swiper/css';
import 'react-photo-view/dist/react-photo-view.css';
import Loader from './components/loader/Loader';
const LazyApp = React.lazy(() => import('./App'))




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<Suspense fallback={<Loader/>}>
   <LazyApp />
</Suspense>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
