import React, { useState } from "react";
import { HashLink, Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import logoAnimation from "../../../assets/coding-logo.json";
import Lottie from "lottie-react";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  return (
    <nav className="w-full text-white z-20 " id="nav">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between md:block">
            <HashLink to="">
              <Lottie
                animationData={logoAnimation}
                loop={true}
                className="w-36 border-transparent"
              />
            </HashLink>
            <div className="transition  border-none bg-blue-600 hover:bg-gray-400 ease-in md:hidden mb-0">
              <button
                className="p-2 text-white rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex justify-end pb-3 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="mt-0  items-center py-6 text-center justify-center justify-self-end space-y-6 sm:space-y-8 md:flex md:space-x-6 md:space-y-0 font-Poppins transform duration-800 md:duration-700 transition-colors  md:bg-transparent w-4/5 mx-auto md:w-full">
              <li>
                <HashLink
                  className="text-white border-2  px-3 hover:text-blue-500 cursor-pointer"
                  onClick={() => setNavbar(!navbar)}
                  to="/"
                  smooth={true}
                  offset={70}
                  duration={500}
                >
                  Home
                </HashLink>
              </li>

              <li>
                <HashLink
                  className="text-white hover:text-blue-500 border-2  px-3"
                  onClick={() => setNavbar(!navbar)}
                  to="/#projects"
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Projects
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="text-white hover:text-blue-500 cursor-pointer border-2  px-3"
                  onClick={() => setNavbar(!navbar)}
                  to="/#contact"
                  smooth={true}
                  offset={500}
                  duration={1000}
                >
                  Contact Me
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="text-white hover:text-blue-500 cursor-pointer border-2  px-3"
                  onClick={() => setNavbar(!navbar)}
                  to="/#about"
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  About Me
                </HashLink>
              </li>

              <li>
                <NavLink
                  className="text-white hover:text-blue-500 border-2  px-3"
                  onClick={() => setNavbar(!navbar)}
                  spy="true"
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
              <li className="flex items-center justify-center gap-8" >
                <a
                  onClick={() => setNavbar(!navbar)}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/anowarzz/"
                  className="text-white hover:text-blue-500 "
                >
                  <FaLinkedin className="text-2xl" />
                </a>

                <a
                  onClick={() => setNavbar(!navbar)}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://github.com/anowarzz"
                  className="text-white hover:text-blue-500"
                >
                  <FaGithub className="text-2xl " />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
