import React from 'react';
import { CirclesWithBar } from 'react-loader-spinner';
import Navbar from '../Shared/Navbar/Navbar';


const Loader = () => {
    return (
      <>
        <div className="h-screen w-screen bg-[#05051d] flex flex-col items-center justify-center">
          <CirclesWithBar
            height="100"
            width="100"
            color="#36D399"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor="#FEDC0B"
            barColor="#FB2576"
            ariaLabel="circles-with-bar-loading"
          />
          <h3 className="text-pink font-bold my-4">Loading...</h3>
        </div>
      </>
    );
};

export default Loader;